export const IconCheck = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 64 64" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                fill="currentColor" stroke="none">
                <path d="M564 548 c-10 -6 -90 -81 -176 -166 l-158 -157 -67 67 c-87 84 -106
                95 -139 73 -17 -11 -24 -25 -24 -47 0 -27 15 -47 98 -130 140 -140 120 -145
                354 90 163 163 188 192 188 219 0 44 -39 70 -76 51z"/>
            </g>
        </svg>
    );
};

export const IconCross = () => {
    return (
        <svg viewBox="0 0 18 18" width="28" height="28" x="0" y="0">
            <polygon fillRule="nonzero" fill="currentColor"
                points="15 10 10 10 10 15 8 15 8 10 3 10 3 8 8 8 8 3 10 3 10 8 15 8"></polygon>
        </svg>
    );
};

export const IconCrossAdd = () => {
    return (
        <svg width="20" height="20" x="0" y="0" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0,512) scale(0.1,-0.1)" fill="currentColor" stroke="none">
                <path d="M2439 5093 c-60 -31 -112 -92 -129 -151 -6 -24 -10 -392 -10 -1079
                l0 -1043 -1042 0 c-688 0 -1056 -4 -1080 -10 -148 -41 -223 -227 -150 -371 30
                -60 91 -112 150 -129 24 -6 392 -10 1080 -10 l1042 0 0 -1042 c0 -688 4 -1056
                10 -1080 41 -148 227 -223 371 -150 60 30 112 91 129 150 6 24 10 392 10 1080
                l0 1042 1043 0 c687 0 1055 4 1079 10 59 17 120 69 151 129 72 144 -3 330
                -151 371 -24 6 -392 10 -1079 10 l-1043 0 0 1043 c0 687 -4 1055 -10 1079 -29
                104 -134 178 -250 178 -53 0 -78 -6 -121 -27z" />
            </g>
        </svg>
    );
};

export const IconCrossDelete = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 64 64" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                fill="currentColor" stroke="none">
                <path d="M17 622 c-10 -11 -17 -35 -17 -57 0 -35 9 -47 102 -141 l102 -104
                -102 -104 c-95 -96 -102 -106 -102 -143 0 -50 23 -73 75 -73 35 0 47 9 141
                102 l104 102 104 -102 c96 -95 106 -102 143 -102 50 0 73 23 73 75 0 35 -9 47
                -102 141 l-102 104 102 104 c95 96 102 106 102 143 0 50 -23 73 -75 73 -35 0
                -47 -9 -141 -102 l-104 -102 -104 102 c-96 95 -106 102 -143 102 -26 0 -46 -6
                -56 -18z"/>
            </g>
        </svg>
    );
};

export const IconDelete = () => {
    return (
        <svg width="30px" height="30px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                <path d="M1870 4901 c-72 -23 -119 -63 -145 -123 -12 -30 -15 -83 -15 -272 l0
                -235 -457 -3 -458 -3 -47 -28 c-84 -49 -124 -150 -98 -245 15 -56 80 -123 136
                -140 60 -18 3488 -18 3548 0 57 17 119 82 135 141 27 95 -13 195 -97 244 l-47
                28 -457 3 -458 3 0 235 c0 189 -3 242 -15 272 -20 47 -50 78 -100 105 -40 22
                -45 22 -720 24 -374 1 -691 -2 -705 -6z m1120 -526 l0 -105 -430 0 -430 0 0
                105 0 105 430 0 430 0 0 -105z"/>
                <path d="M1008 3618 c-21 -5 -58 -28 -83 -50 -81 -71 -76 20 -73 -1394 l3
                -1249 23 -80 c29 -107 99 -246 162 -323 107 -131 271 -236 445 -285 l80 -22
                995 0 995 0 80 22 c174 49 338 154 445 285 63 77 133 216 162 323 l23 80 3
                1240 c2 890 0 1255 -8 1292 -8 36 -23 65 -51 97 -99 113 -276 89 -343 -45
                l-26 -53 0 -1232 c0 -1200 -1 -1234 -20 -1296 -39 -128 -140 -228 -269 -269
                -60 -18 -99 -19 -991 -19 -897 0 -930 1 -992 20 -128 39 -228 140 -269 269
                -18 61 -19 102 -19 1296 l0 1233 -28 53 c-46 89 -149 134 -244 107z"/>
                <path d="M2062 3189 c-46 -14 -118 -88 -131 -135 -15 -54 -15 -1574 0 -1628
                15 -54 83 -119 142 -135 95 -27 195 13 244 97 l28 47 0 805 0 805 -28 47 c-49
                84 -160 126 -255 97z"/>
                <path d="M2889 3172 c-39 -20 -62 -41 -84 -77 l-30 -48 0 -806 0 -806 28 -47
                c49 -84 149 -124 244 -97 59 16 124 78 141 135 17 59 17 1569 0 1628 -16 53
                -81 118 -134 134 -64 19 -107 15 -165 -16z"/>
            </g>
        </svg>
    );
};

export const IconLink = () => {
    return (
        <svg width="28" height="28" x="0" y="0" viewBox="0 0 512 512">
            <path fill="currentColor"
                d="M313.9 75.4c-5.9 2.1-9.6 5.3-12.3 10.8-4.6 9.2-2.9 20.5 4 27 5.7 5.3 8.4 5.8 32.6 5.8h22.3l-60.2 60.2c-33 33.2-60.8 61.8-61.7 63.5-8.1 16.2 5.3 35.4 22.9 33 2.5-.4 6-1.4 7.8-2.3 1.7-.9 30.3-28.7 63.5-61.7l60.2-60.2V174c0 24.8.6 27.5 6.6 33.2 12.7 11.9 35.5 4.4 37.9-12.6.3-2.2.5-26.6.3-54.1-.3-46.6-.4-50.2-2.2-53.5-2.6-4.9-5.5-7.9-10.1-10.3-3.9-2.2-4.9-2.2-56-2.4-40.8-.2-52.8 0-55.6 1.1z" />
            <path fill="currentColor"
                d="M126 95.6c-3.6.7-10.1 3.1-14.5 5.3-16.8 8.4-28.1 21.4-34.3 39.6l-2.7 8v234l2.9 8.8c6.8 20.8 22.5 36.5 43.2 43.3l8.9 2.9h234l8-2.7c23.1-7.8 40.2-26.6 45-49.4 1.2-5.5 1.5-16.9 1.5-57.4v-50.5l-2.4-5.1c-3.7-7.9-11-12.4-20.1-12.4-9 0-15.9 4.2-19.9 12.2l-2.6 5.2v47.9c0 33.1-.4 49.2-1.1 51.8-1.6 5-6.1 10.5-11.2 13.2l-4.2 2.2-107 .3c-76.1.2-108.5 0-112.2-.8-6.9-1.5-12.5-5.7-15.4-11.7l-2.4-4.8v-220l2.2-4.2c2.7-5.1 8.2-9.6 13.2-11.2 2.6-.7 18.8-1.1 51.9-1.1h48l5.3-2.8c9.6-5 13.9-15.9 10.8-26.9-1.6-5.9-7.8-12.4-13.5-14.1-6.3-1.8-102.5-1.6-111.4.4z" />
        </svg>
    );
};

export const IconExit = () => {
    return (
        <svg width="25px" height="25px" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                <path d="M930 4511 c-88 -28 -140 -60 -201 -120 -61 -61 -97 -123 -123 -210
                -14 -49 -16 -210 -16 -1621 0 -1715 -4 -1604 57 -1724 32 -64 125 -157 189
                -189 115 -58 98 -57 838 -57 l681 0 51 24 c166 77 205 296 74 426 -81 82 -64
                80 -757 80 l-603 0 0 1440 0 1439 623 3 c595 3 624 4 662 23 202 100 203 387
                1 481 l-51 24 -685 -1 c-629 0 -690 -2 -740 -18z"/>
                <path d="M3274 3676 c-97 -45 -164 -162 -150 -263 13 -94 36 -126 268 -359
                l222 -224 -830 0 c-917 0 -891 2 -976 -63 -131 -100 -131 -314 0 -414 85 -65
                59 -63 976 -63 l830 0 -222 -224 c-232 -233 -255 -265 -268 -359 -14 -101 53
                -218 150 -263 66 -31 162 -32 226 -3 34 15 159 134 508 482 255 254 475 482
                490 507 24 39 27 56 27 130 0 74 -3 91 -27 130 -15 25 -235 253 -490 507 -349
                348 -474 467 -508 482 -64 29 -160 28 -226 -3z"/>
            </g>
        </svg>
    );
};